export const bodyParts = {
  // The most notable physical trait of $currentNPC.firstName is that $currentNPC.heshe has ______
  head: {
    hair: [
      'thick, long hair down to their hips',
      'a mostly shaved head',
      'a short crewcut',
      'a pretty short mohawk',
      'neatly trimmed hair',
      'wild, unkempt hair',
      'long curly hair',
      'short curly hair',
      'thick straight hair',
      'thin stringy hair',
      'small tufts of hair, but not much',
      'short braided hair',
      'hair in thick long braids',
      'thick braids of hair banded with gold',
      'short, messy hair',
      'a very short, no-nonsense haircut',
      'a short, very tailored haircut',
      'bangs that obscure the eyes',
      'hair flaked with snowy dandruff',
      'a hair braid like a thick rope',
      'hair pulled up in a tight bun',
      'hair coiled in a top-knot',
      'greasy looking hair',
      'rather oily looking hair',
      'thin whispy hair down to the shoulders',
      'hair elaborately dressed with ribbons',
      'hair greased into a ducktail',
      'locks that flutter to the floor',
      'incredibly frizzy hair',
      'hair like straw',
      'hair that droops down to the cheeks',
      'matted looking hair',
      'pigtails',
      'a very noble haircut',
      'long messy hair',
      'hair in a low bun',
      'has slicked back hair',
      'braided hair',
      'a ring of hair',
      'barely any hair',
      'long bangs',
      'a bowl cut',
      'very bushy hair',
      "a rat's nest for hair",
      'a shaved head',
      'long sideburns',
      'thin, neatly trimmed sideburns',
      'thick, tangled hair',
      'neatly combed hair'
    ],
    // covers eyes, eyelids, and eyebrows
    eyes: [
      'eyes like a hawk',
      'quick-witted eyes',
      'incredibly sharp eyes',
      'rather dull eyes',
      'cold eyes',
      'lifeless looking eyes',
      'incredibly wide eyes',
      'slits for eyes',
      'very droopy eyelids',
      'rather square shaped eyes',
      'brilliant eyes',
      'shifty eyes',
      'deep crows feet around both eyes',
      'almond shaped eyes',
      'beady rat eyes',
      'a beseeching gaze',
      'a blazing gaze',
      'bug-eyes',
      'bulging eyes',
      'feline-like eyes',
      'eyes like a shark',
      'eyes like liquid pools of color',
      'sneaky, close-set eyes',
      'very sunken eyes',
      'constantly watery eyes',
      'narrow, wide-set eyes',
      'moon shaped eyes',
      'steeply arched brows',
      'tired looking eyes',
      'a thick unibrow',
      'a thin unibrow',
      'thick, straight eyebrows',
      'very hairy eyebrows',
      'thin, curved brows',
      'outrageously long eyelashes',
      'very long eyelashes',
      'eyebrows like checkmarks',
      'well manicured eyebrows',
      'eyebrows like caterpillars',
      'small beady eyes',
      'strangely bloodshot eyes',
      'deep-set eyes',
      'quite owlish eyes',
      'oval shaped eyes',
      'slanted eyes',
      'drawn on eyebrows',
      'quite sparse eyebrows',
      'thin, wispy eyebrows',
      'doe-eyes',
      'an emotionless gaze',
      'hollow eyes',
      'squinty eyes',
      'twinkling eyes',
      'wide spaced, sunken eyes',
      'a black eye',
      'two black eyes',
      'enormous eyes',
      'incredibly tiny eyes'
    ],
    nose: [
      'a long slender nose',
      'a rather snout looking nose',
      'a piggish nose',
      'a round, bulbous nose',
      'a short slender nose',
      'a bird-like nose',
      'a beaked nose',
      'a rather bent looking nose',
      "a boxer's nose",
      'a broken nose',
      'a very large nose',
      'a very small nose',
      'a quite long nose',
      'a rather askew nose',
      'a bovine looking nose',
      'a broad, flat nose',
      'a childlike nose',
      'a chiseled nose',
      'a cleft nose',
      'a crooked nose',
      'a short, curved nose',
      'a long, curved nose',
      'a curved nose',
      'a delicate looking nose',
      'a dimpled nose',
      'an elegantly curved nose',
      'an elegant, round nose',
      'an enormous nose',
      'a cat-like nose',
      'a flaccid looking nose',
      'a freckled nose',
      'a fat nose',
      'a hooked nose',
      'a thin, hooked nose',
      'a wide, hooked nose',
      'an impish nose',
      'a knobby nose',
      'a long, lopsided nose',
      'a narrow, lopsided nose',
      'a rather lumpy',
      'a long, lumpy nose',
      'a round, lumpy nose',
      'a flat, meaty nose',
      'an oddly misshapen nose',
      'a short pinched nose',
      'a squashed nose',
      'a wide, wrinkled nose',
      'a rather noble nose',
      'a thin, sloped nose',
      'a broad, sloped nose',
      'a warty nose',
      'nose covered in blackheads',
      'a rather greasy looking nose',
      'a pockmarked nose'
    ],
    // covers mouth and cheeks
    mouth: [
      'long thin lips',
      'thick, full lips',
      'a permanent smile',
      'a permanent frown',
      'a permanent scowl',
      'very pouty lips',
      'a permanent look of awe',
      'tight, pursed lips',
      'rather dainty lips',
      'long, thick lips',
      'narrow, thin lips',
      'yellowed teeth',
      'incredibly crooked teeth',
      'almost rotten looking teeth',
      'almost no teeth',
      'a perfect smile',
      'incredibly white teeth',
      'perfectly straight teeth',
      'teeth like headstones',
      'plump, luscious lips',
      'very wide lips',
      'troutlike lips',
      'dry, cracked lips',
      'leathery looking lips',
      'a sagging lower lip',
      'very full lips',
      'a fat lip',
      'a kind smile',
      'hallowed cheeks',
      'harsh cheekbones',
      'sharp cheekbones',
      'rosy red cheeks',
      'very high cheekbones',
      'sunken cheekbones',
      'soft round cheeks',
      'plump cheeks',
      'chubby cheeks',
      'dimpled cheeks',
      'large puffy cheeks',
      'thin ruddy cheeks',
      'pale, sunken cheeks',
      'freckled cheeks',
      'large buck teeth',
      'teeth like a beaver',
      'teeth like a horse',
      'a large gap between the two front teeth',
      'an underbite',
      'an overbite'
    ],
    // covers chin, jaw, and neck
    chin: [
      'a strong, jutting chin',
      'an angular chin',
      'a strong cleft chin',
      'a slight cleft chin',
      'a double chin',
      'a large double chin',
      'a wide beefy chin',
      'a thin dainty chin',
      'a rather bony chin',
      'a wide chin',
      'a narrow, sunken chin',
      'a very feminine chin',
      'a flat chin',
      'a recessed chin',
      'an almost non-existant chin',
      'a misshapen chin',
      'a noble looking chin',
      'a flat, pockmarked chin',
      'a very prominent chin',
      'a sharp chin',
      'a quite slender chin',
      'a well-molded chin',
      'a square chin',
      'a soft, rounded chin',
      'a chiseled jawline',
      'a well sculpted jaw',
      'a chin like a shovel',
      'a delicately rounded jaw',
      'a caved in chin',
      'a heavy round jaw',
      'a jaw narrowed into a pointed little chin',
      'jowls',
      'a narrow jawline that arrows into a pointed chin',
      'a pick-like chin',
      'a receding chin that makes their nose seem bigger',
      'a saggy jaw that droops into a turkey neck',
      'a spade of a chin',
      'a strong square jaw',
      'a weak chin',
      'a prominent jawline',
      'a smooth, youthful jawline',
      'a long elegant neck',
      'a long stocky neck',
      'a very wide neck',
      'a quite short, thin neck',
      'a thin stocky neck',
      'a narrow neck',
      'a neck that looks to small for their head',
      'an incredibly thick neck'
    ],
    ears: [
      'jug-like ears',
      'elephant-like ears',
      'cauliflower ears',
      'large, protruding ears',
      'small, protruding ears',
      'long, thick earlobes',
      'thin, fat earlobes',
      'very tiny ears',
      'small tender ears',
      'big, floppy ears',
      'no earlobes',
      'ears that curl like an exotic shell',
      'rather pointed ears',
      'very rounded ears',
      'very delicate ears',
      'rather hairy ears'
    ],
    misc: [
      'a densely pockmarked face',
      'an incredibly freckly face',
      'a face covered in freckles',
      'a well freckled face',
      'a rather angular face',
      'a soft rounded face',
      'a friendly looking face',
      'a sunken face',
      'a hollow looking face',
      'a sharply angled face',
      'a long, horselike face',
      'a horseface',
      'an exceptionally pale face',
      'a very blocky face',
      'a baby-face',
      'a rather blotchy looking face',
      'a well countoured face',
      'a flabby face',
      'a youthful looking face'
    ]
  },
  torso: {
    descriptions: [
      'a hunched back',
      'an incredibly large hunch',
      'very broad shoulders',
      'incredibly broad shoulders',
      'quite broad shoulders',
      'narrow shoulders',
      'quite rounded shoulders',
      'terrible posture',
      'perfect posture',
      'a large barrel chest',
      'a barrel chest',
      'very bony shoulders',
      'wide set shoulders',
      'a sunken chest',
      'a wide ribcage',
      'a narrow ribcage',
      'a very narrow waist',
      'a rather wide waist',
      'a distended stomach',
      'rather drooped shoulders',
      'strong, square shoulders',
      'broad, powerful shoulders',
      'very pronounced collar bones',
      'wide, bony hips',
      'very narrows hips',
      'very wide set hips',
      'a misshapen ribcage'
    ]
  },
  arms: {
    descriptions: [
      'long, gangly arms',
      'quite short arms',
      'arms like a gorilla',
      'long, spindly fingers',
      'short, stubby arms',
      'short, stubby fingers',
      'child-sized hands',
      'very hairy forearms',
      'incredibly veiny arms',
      'swollen, red knuckles',
      'quite bony elbows',
      'fingers like sausages',
      'heavily calloused hands',
      'fingernails cut to the quick',
      'long, brittle fingernails',
      'colorfully painted nails',
      'talon-like nails',
      'nails sharpened to a point',
      'neatly trimmed fingernails',
      'small webs between each finger',
      'long, elegant fingers',
      'rough, cracked hands',
      'large swollen knuckles',
      'impossibly enormous hands',
      'very muscular hands',
      'quite muscular arms',
      'grimy looking fingernails',
      'yellow-ish fingernails'
    ]
  },
  legs: {
    descriptions: [
      'more legs than torso',
      'widely bowed legs',
      'very notcieable knock knees',
      'large, knobly knees',
      'quite stumpy legs',
      'large, wide feet',
      'very tiny feet',
      'feet like a toddler',
      'feet like a chimp',
      'almost no kneecaps',
      'long, monkey like toes',
      'pale, veiny legs'
    ]
  }
}
